<template>
  <div>
    <div class="content-design" @click='showtype=false'>
      <div class="all">
        
        <div class="table">
          <el-tabs v-model="activeName" @tab-click="handleClick" >
          <el-tab-pane label="待指派" name="first"></el-tab-pane>
          <el-tab-pane label="派工记录" name="seco"></el-tab-pane>
          </el-tabs>
        <div class="SearchBar"  v-if="activeName!='first'">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small" label-width="90px" >
            
            <el-form-item label="工程编号/名称" label-width="110px">
              <el-input
                v-model="search.numberOrName"
                placeholder="请输入档案号/合同编号"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="工程类型">
              <el-select v-model="search.projectType" clearable placeholder="请选择">
                <el-option
                  v-for="item in TypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工程状态">
              <el-select v-model="search.status" clearable placeholder="请选择">
                <el-option
                  v-for="item in statusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="现场管理员">
              <el-select v-model="search.siteManager" clearable placeholder="请选择">
              <el-option
                v-for="item in AllSiteManager"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="时间类型" label-width="110px">
              <el-select v-model="search.timeType"  placeholder="请选择">
                  <el-option label="派工时间" :value="1"></el-option>
                  <el-option label="登记时间" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="派工时间"  v-if="search.timeType == 1">
               <el-date-picker
                v-model="searchDatatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd  HH:mm:ss">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="登记时间"  v-if="search.timeType == 2">
               <el-date-picker
                v-model="searchDatatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd  HH:mm:ss">
                </el-date-picker>
            </el-form-item>
            <el-form-item label=" " label-width="10" >
              <div>
                <el-button type="primary"  size="small" @click="getList(1)">查询</el-button>
                <el-button type="primary" plain  size="small" @click="reset">重置</el-button>
              </div>
             </el-form-item>
          </el-form>
        </div>
          <!--数据表格-->
          <div v-show="activeName=='first'">
          <el-table 
            ref="tableData"
            :data="tableData"
            style="width: 100%"
            border
            :stripe="true"
            height="calc(100vh - 315px)"
            @sort-change="sort_change"
            
          >
          
           <el-table-column 
                type="index"
                label="序号"
                width="70" align="center">
                </el-table-column>
                <el-table-column 
              prop="projectNumber" 
              label="工程编号"
              align="center"
              show-overflow-tooltip  
            > </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="projectType"
              label="工程类型"
              align="center"
              show-overflow-tooltip
               
            >
            </el-table-column>
            <el-table-column
              prop="contractTypeName"
              label="施工合同"
              align="center"
              show-overflow-tooltip
              width="100"
            >
            <template slot-scope="{ row }">
              <!-- <a :href="row.contractAttachment"  target="_back">查看</a> -->
              <!-- <a :href="row.contractAttachment" download="" target="_back">查看</a> -->             
              <el-button 
                    size="small" @click="showImg(row)"
                    >查看</el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop="realname"
              label="创建人"
              align="center"
              show-overflow-tooltip
              width="100px"
            >
            </el-table-column>

            <el-table-column
              prop="createTime" 
              label="创建时间"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="status" 
              label="状态"
              align="center"
              show-overflow-tooltip
              width="100"
            >
                <template slot-scope="{ row }">
                    <el-tag v-if="row.status" :type="row.status[row.status.length-1]=='中'?'warning':(row.status[row.status.length-1]=='成'?'success':'')">{{ row.status }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left"  width="100">
              <template slot-scope="{ row }">
                   <el-button 
                    type="primary"
                    size="small" @click="showadd(row)"
                    >派工</el-button>
              </template>
            </el-table-column>
          </el-table>
           <el-pagination
            :current-page="searchfirst.current"
            :background="true"
            :page-sizes="[ 30, 50, 100]"
            :page-size="searchfirst.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handlefirstSizeChange"
            @current-change="handlefirstCurrentChange"
          >
          </el-pagination>
          </div>
        <div v-show="activeName!='first'">
          <el-table 
            ref="tableData"
            :data="recordList"
            height="calc(100vh - 425px)"
            border
            class="table-search2"
          >
          
           <el-table-column 
                type="index"
                label="序号"
                width="70" align="center">
                </el-table-column>
                
            <el-table-column
              prop="projectNumber"
              label="工程编号"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
               
            >
            </el-table-column>
            <el-table-column
              prop="projectType"
              label="工程类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="realname"
              label="登记人"
              align="center"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            
            <el-table-column
              prop="createTime" 
              label="登记时间"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="siteManager" 
              label="现场管理员"
              align="center"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="dispatchBy" 
              label="派工人"
              align="center"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="dispatchTime" 
              label="派工时间"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="status" 
              label="状态"
              align="center"
              width="100"
              show-overflow-tooltip
            >
                <template slot-scope="{ row }">
                    <el-tag v-if="row.status" :type="row.status[row.status.length-1]=='中'?'warning':(row.status[row.status.length-1]=='成'?'success':'')">{{ row.status }}</el-tag>
                </template>
            </el-table-column>
           
            <el-table-column prop="operation" label="操作" align="left" width="250">
              <template slot-scope="{ row }">
                <el-button 
                    type="primary"
                    v-if="row.status=='已派工'"
                    size="small" @click="showadd(row)"
                    >编辑</el-button>
                   <el-button 
                    type="primary"
                    v-if="starte(row.status)"
                    size="small" @click="look(row)"
                    >查看</el-button>
                    <el-button 
                    type="primary"
                    v-if="direction(row.status) && row.projectTypeId != 547 && !row.drillingId"
                    size="small" @click="showadd(row,'定向')"
                    >定向钻</el-button>
                    <el-button 
                    type="primary"
                    v-if="earthwork(row.status) && row.projectTypeId != 547 && !row.earthworkId"
                    size="small" @click="showadd(row,'土方')"
                    >土方</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
          <el-pagination
            :current-page="search.current"
            :background="true"
            v-if="activeName!='first'"
            :page-sizes="[ 30, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        </div>
        </div>
      </div>
    </div>
    <!-- 派工 -->
    <el-dialog
      title="派工"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form ref="addform" :model="form" label-width="140px" :rules="rule" size="small">
       <el-form-item label="现场管理员：" prop="siteManagerId" v-if="form.statusCode < 3">
            <el-select
              v-model="form.siteManagerId"
              placeholder="请选择"
              filterable
              :style='breadth'
              value-key="id"
            >
              <el-option
                v-for="item in AllSiteManager"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="安装施工队：" prop="installId" v-if="form.statusCode < 3">
            <el-select
              v-model="form.installId"
              placeholder="请选择"
              filterable
              :style='breadth'
              value-key="id"
            >
              <el-option
                v-for="item in InstallTeam"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="土方施工队：" :prop="troops =='土方' ? 'earthworkId' : '' " v-if="form.projectTypeId!=547 && (troops =='土方' || !troops)">
            <el-select
              v-model="form.earthworkId"
              placeholder="请选择"
              filterable
              :style='breadth'
              value-key="id"
            >
              <el-option
                v-for="item in EarthworkTeam"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="定向钻施工队：" :prop="troops =='定向' ? 'drillingId' : ''" v-if="form.projectTypeId!=547 && (troops =='定向'|| !troops)">
            <el-select
              v-model="form.drillingId"
              placeholder="请选择"
              filterable
              :style='breadth'
              value-key="id"
            >
              <el-option
                v-for="item in DrillingTeam"
                :key="item.id"
               :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="派工说明：" prop="dispatchExplain" v-if="form.statusCode < 3">
            <el-input
              v-model="form.dispatchExplain"
              placeholder="请输入"
              :style='breadth'
              class="SearchInput"
              type="textarea"
              maxlength='100'
              clearable
            ></el-input>
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-right:10px" @click="closeAdd()">取消</el-button>
        <el-button type="primary" @click="saveadd">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 查看 -->
    <el-dialog
      :visible.sync="lookSatre"
      width="50%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form
        :inline="true"
        ref="addform1"
        :model="form"
        label-width="120px"
        size="mini"
      >
        <div>
          <div class="particulars">
            <span class="img"></span>
            <span>工程信息</span>
            <div class="state">{{dataObj.status}}</div>
          </div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="工程编号：" prop="positionName">
                {{ dataObj.projectNumber }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工程名称：" prop="positionName">
                {{ dataObj.projectName }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工程类型：" prop="positionName">
                {{ dataObj.projectType }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工程说明：" prop="positionName">
                {{ dataObj.projectExplain }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="创建人：" prop="positionName">
                {{ dataObj.realname }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间：" prop="positionName">
                {{ dataObj.createTime }}
              </el-form-item>
            </el-col>
          </el-row>
          <div class="particulars">
            <span class="img"></span>
            <span>工程派工信息</span>
          </div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="现场管理员：">
                {{ dataObj.siteManager }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="安装施工队：">
                {{ dataObj.installName }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="土方施工队：">
                {{ dataObj.earthworkName }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="定向钻队：">
                {{ dataObj.drillingName }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="派工人：" prop="positionName">
                {{ dataObj.dispatchName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="派工时间：" prop="positionName">
                {{ dataObj.dispatchTime }}
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-right:10px" @click="lookSatre = false">取消</el-button>
      </div>
    </el-dialog>
    <show-img :imgList="imgList" ref="imgList"></show-img>
  </div>
  
</template>
<script>
import {
  waitDispatch,
  getAllSiteManager,
  getInstallTeam,
  dispatchSave,
  dispatchList,
  getEarthworkTeam,
  getDrillingTeam,
  projectStatus,
  dispatchUpdate,
  dispatchProjectInfo
} from "@/RequestPort/earlier/index.js";
import { getDict } from "@/apis/commonType.js"
import showImg from '@/components/showImg.vue'
export default {
  components: {showImg},
  name: 'applyList',
  mixins: [],
  data() {
    return {
      search: { current: 1, size: 100,timeType:1}, //搜索
      searchfirst: { current: 1, size: 100},
      searchDatatime:[],
      total: 10,
      tableData: [], //列表对象
      recordList:[],// 派工记录
      dialogVisible:false,
      // 新增编辑输入框宽度
      breadth:'width:350px',
      // 派工
      form:{
        earthworkId:'',
        drillingId:'',
        dispatchExplain:''
      },
      rule:{
         siteManagerId:{ required: true, message: "请选择现场管理员", trigger: "change" },
         installId:{ required: true, message: "请选择安装施工队", trigger: "change" },
         earthworkId:{ required: true, message: "请选择土方施工队", trigger: "change" },
         drillingId:{ required: true, message: "请选择定向钻施工队", trigger: "change" },
      },
      activeName:'first',
      username:'',
      // 查看
      lookSatre:false,
      dataObj:{},
      troops:'',
      teamList:[],
      // 现场管理员
      AllSiteManager:[],
      // 安装施工队
      InstallTeam:[],
      // 土方
      EarthworkTeam:[],
      // 定向钻施工队下拉列表
      DrillingTeam:[],
      // 状态
      statusList:[],
      // 工程类型
      TypeList:[],
      imgList:[]
    }
  },
  mounted() {
    this.userName = localStorage.getItem("userName");
    this.getList();
    getAllSiteManager().then(res => {
      this.AllSiteManager = res.data
    })
    getInstallTeam().then(res => {
      this.InstallTeam = res.data
    })
    getEarthworkTeam().then(res => {
      this.EarthworkTeam = res.data
    })
    getDrillingTeam().then(res => {
      this.DrillingTeam = res.data
    })
    this.getprojectStatus()
  },
  methods: {
    onDownFile(url) {
       window.open(url);
    },
    showImg(row){
        let file=row.contractAttachment.split(',')
          
          if(file&&file.length>0){
            let str=file[0]
            if(str.substring(str.length-4)=='.pdf'){
               file.forEach(item => {
                   this.downfile(item)
               });
            }else{
              this.$refs.imgList.setdialogVisible(true);
              this.imgList = file;
            }
             
          }else{
              this.$message.error('暂无文件')
          }
          
      },
      downfile(url) {
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.target="_blank"
      
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          
        },
    /**
       * 工程状态
       */
      getprojectStatus(){
          projectStatus().then(res => {
              this.statusList = res.data
              this.statusList.forEach((el,index) => {
                if(el.id == 1) {
                  this.statusList.splice(index,1)
                }
              })
          })
          // 工程类型
          getDict({parentCode:2100}).then(res => {
            this.TypeList = res.data
          })
      },
    /**
     * 查看状态
     */
    starte(stare){
      if(stare == '已派工'|| stare == '已交底'||stare == '施工中'||stare == '验收中' || stare == '验收完成'){
        return true
      }  else {
        return false
      }
    },
    /**
     * 方向钻
     */
    direction(stare){
      if(stare == '已交底'||stare == '施工中'||stare == '验收中'){
        return true
      }  else {
        return false
      }
    },
    /**
     * 土方
     */
    earthwork(stare){
      if(stare == '已交底'||stare == '施工中'||stare == '验收中'){
        return true
      }  else {
        return false
      }
    },
    /**
     * 查看
     */
    look(row){
      dispatchProjectInfo({id:row.id}).then(res => {
        this.dataObj = res.data
       })
      this.lookSatre = !this.lookSatre
    },
     sort_change(column){
      this.search.sortColumn=column.prop;
      if(column.order=='ascending'){
        this.search.sortKey =0
      }else{
        this.search.sortKey =1
      }
      this.getList(1)
    },
    handleClick(){
        this.reset()
    },
    getList(current) {
      if (current) {
        this.search.current = 1
        this.searchfirst.current = 1
      }
      if(this.searchDatatime){
        this.search.startTime = this.searchDatatime[0]
        this.search.endTime = this.searchDatatime[1]
      }
      //查列表
      if(this.activeName=='first'){
        waitDispatch(this.searchfirst).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
       })
      }else{
        dispatchList(this.search).then(res => {
        this.recordList = res.data.records
        this.total = res.data.total
       })
      }
      
    },
   
    //重置查询条件
    reset() {
      (this.search = {
        current: 1,
        size: 100,
      }),
      this.searchDatatime=[];
        this.getList()
    },
    handleSizeChange(val) {
      this.search.size = val
      this.getList(1)
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    },
    handlefirstSizeChange(val) {
      this.searchfirst.size = val
      this.getList(1)
    },
    handlefirstCurrentChange(val) {
      this.searchfirst.current = val
      this.getList()
    },
    // 点击派工
    showadd(row,val){
      this.dialogVisible = true;
      this.form= {
        drillingId:'',
        earthworkId:'',
        dispatchExplain:'',
        ...row,        
        };
        
      this.troops = val
      if(this.$refs['addform']){
      this.$refs['addform'].resetFields()
      }
    },
    closeAdd(){
       this.dialogVisible = false
        this.$refs['addform'].resetFields()
    },
    /**
     * 关闭弹窗清空
     */
    clearnF() {
      this.form = {
        contractCode:'',
        archivesCode: '',
        earthworkId:'',
        drillingId:'',
        dispatchExplain:''
      }
      this.$refs['addform'].resetFields()
    },
    /**
     * 派工
     */
    saveadd(){
      let that = this
      this.$refs['addform'].validate(valid => {
        if (valid) {
            if(that.troops) {
              let data = {
                drillingId:that.form.drillingId,
                earthworkId : that.form.earthworkId,
                id:that.form.id
              }
              // console.log(data)
              dispatchUpdate(data).then(res=>{
                if(res.code=='200'){
                  that.$message.success('添加成功');
                  that.clearnF()
                  that.getList(1);
                  that.dialogVisible=false;

                }
            })
            }else {
              dispatchSave(that.form).then(res=>{
                if(res.code=='200'){
                  that.$message.success('添加成功');
                  that.clearnF()
                  that.getList(1);
                  that.dialogVisible=false;

                }
            })
            }
        }
      })
    },
    addUser(row){
        apply_findById({id:row.id}).then(res=>{
          if(res.code==200){
            this.form = res.data;
            this.form.urlList
            this.dialogVisible = true;
          }
        })

        
    },
  }
}
</script>

<style  lang="less" scoped>
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  padding-bottom:10px;
  background-color: #ffffff;
}
.SearchInput {
  width: 100%;
}
.SearchOptions {
  width: 100%;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;

   background-color: #ffffff;
   height: auto;
}

.all{height: auto;}
.content-design {
  background: #fff; 
  border-radius: 6px;
  margin:10px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 2%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
.particulars {
  display: flex;
  flex-direction: row;

  height: 40px;
  position: relative;
  .img {
    border: 1px solid #3f74d5;
    height: 20px;
    margin-right: 2px;
  }
  .state {
    width: 60px;
    height: 30px;
    position: absolute;
    top: -10px;
    left: 80px;
    color: #f18d1b;
    line-height: 30px;
    text-align: center;
    border: 1px solid #f18d1b;
  }
}
</style>
