<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
      @open="opDialog"
    >
      <div>
        <div class="bigImg">
          <el-carousel
            :interval="0"
            arrow="always"
            :autoplay="false"
            indicator-position="none"
            style="height: 400px"
            ref="carousel"
            @change="changNUm"
          >
            <el-carousel-item v-for="(item, key) in imgList" :key="key">
              <a @click="handlePreView(item)" target="_blank">
                <img :src="item" style="max-width: 100%; max-height: 400px" />
              </a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="showNum">{{ showNum }}/{{ imgList.length }}</div>
        <div class="smllImg">
          <img
            v-for="(item, key) in imgList"
            :key="key"
            :class="key == showNum - 1 ? 'sImg sImgAtive' : 'sImg'"
            :src="item"
            @click="showImg(key)"
          />
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="图片预览"
      :visible.sync="dialogVisibleImg"
      width="100%"
      :close-on-click-modal="false"
    >
      <div><img :src="dialogImageUrl" width="100%" /></div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  name: "applyList",
  mixins: [],
  data() {
    return {
      dialogVisible: false,
      bigImgSrc: "",
      form: {},
      showNum: 1,
      dialogVisibleImg: false,
      dialogImageUrl: "",
    };
  },
  props: {
    imgList: {
      type: Array,
    },
  },
  mounted() {},
  methods: {
    showImg(key) {
      this.setActiveItem1(key);
    },
    changNUm(val) {
      this.showNum = val + 1;
    },
    setActiveItem1(val) {
      this.$refs.carousel.setActiveItem(val);
    },
    opDialog() {
      this.showNum = 1;
    },
    clearnF() {
      this.form = {};
    },

    setdialogVisible(flag) {
      this.dialogVisible = flag;
    },
    handlePreView(url) {
      this.dialogVisibleImg = true;
      this.dialogImageUrl = url;
    },
  },
};
</script>
<style  lang="less" scoped>
::v-deep.el-carousel__container {
  height: 400px;
}
.smllImg {
  margin: 20px;
  width: 100%;
  overflow: auto;
}
.bigImg {
  text-align: center;
  vertical-align: middle;
  height: 400px;
  line-height: 400px;
  background-color: #f9fafe;
  border-radius: 3px;
}
.iocnsize {
  font-size: 40px;
}
.showNum {
  text-align: right;
  margin: 10px;
  font-size: 20px;
}
.sImg {
  width: 100px;
  height: 100px;
  margin: 20px;
  border: 1px solid #f9fafe;
}
.sImgAtive {
  border: 1px solid #0f71e24a;
  border-radius: 6px;
}
</style>
